@import '../assets/css/mixin.scss';
.walletLeft {width: 50%; height: calc(100vh - 90px); overflow: auto;
  @include respond-to(media-lg) {height: calc(100vh - 70px);}
  @include respond-to(media-md) {width: 100%; background: rgba(0,0,0,.8); position: relative; z-index: 10;}
  @include respond-to(media-sm) {height: auto;}
  .inner {padding: 30px; max-width: 850px; width: 100%;
    @include respond-to(media-xs) {padding: 30px 15px 20px 15px;}
    .left {max-width: 600px;}
  }
}
.walletText {color: #BBBBBB; font-size: 22px; line-height: 32px;
  @include respond-to(media-xl) {font-size: 20px; line-height: 30px;}
  @include respond-to(media-sm) {font-size: 18px; line-height:28px;}
}
.walletBox {background: #1E1F21; border-radius: 10px; padding: 10px; font-size: 18px;
  .text {padding: 0 10px;
    .icon {width: 30px; height: 30px; background: #fff; border-radius: 100%;
      img {max-width: 50%; max-height: 50%; width: auto; height: auto;}
    }
  }
  .connectBtn {background: $primary; border-radius: 10px; padding: 15px 20px;
    &:hover {color: #fff !important; opacity: .8;}
  }
}
.walletSubHd {font-size: 24px; line-height: 32px;
  @include respond-to(media-lg) {font-size: 20px; line-height: 28px;}
}
.walletList {
  li {font-size: 17px; line-height: 26px; margin-bottom: 15px; counter-increment: step-counter; position: relative; min-height: 50px; padding-left: 65px; display: flex; align-items: center;
    @include respond-to(media-sm) {padding-left: 55px; min-height: 45px; font-size: 16px; line-height: 24px;}
    &:last-child {margin-bottom: 0;}
    &:before {content: counter(step-counter); width: 50px; height: 50px; background: #1E1E1E; border: 1px solid #636363; font-size: 18px; border-radius: 100%; position: absolute; left: 0; top: 5px; display: flex; align-items: center; justify-content: center;
      @include respond-to(media-sm) {width: 40px; height: 40px; font-size: 16px;}
    }
    a {color: #0077FF !important; text-decoration: underline !important;
      &:hover {color: #fff !important;}
    }
  }
}

.walletRight {width: 50%; height: calc(100vh - 90px);
  @include respond-to(media-lg) {height: calc(100vh - 70px);}
  @include respond-to(media-md) {width: 100%; position: fixed; left: 0; bottom: 0;}
}