@import '../../assets/css/mixin.scss';
.adminTop {min-height: calc(100vh - 60px); padding: 20px;}
.AdminFooter {border-top:1px solid rgba(255,255,255,.2); font-size: 16px; color: #D6D6D6;
  @include respond-to(media-xs) {font-size: 14px;}
  .container {height: 52px;}
}
.loginForm {background: #0A0A0C; border-radius: 10px; width: 100%; max-width: 700px; margin: auto; padding: 45px;
  @include respond-to(media-xs) {padding: 20px;}

  .inner {max-width: 500px; width: 100%; margin: auto;}
  .loginHd {font-size: 34px; font-family: $primary-font;
    @include respond-to(media-lg) {font-size: 28px;}
    @include respond-to(media-sm) {font-size: 22px;}
  }
}
.rem {font-size: 16px; color: $grayClr;
  @include respond-to(media-xs) {font-size: 14px;}
}
a.fp {font-size: 16px; color: $grayClr;
  @include respond-to(media-xs) {font-size: 14px;}
}
a.back {font-size: 16px; color: $grayClr;
  @include respond-to(media-xs) {font-size: 14px;}
}
.hdText {font-size: 16px; line-height: 22px; color: $grayClr;
  @include respond-to(media-xs) {font-size: 15px; line-height: 20px;}
  a {color:#fff;}
}
.eye {position: absolute; right: 10px; top: 50%; color: $grayClr; margin-top: -12px;}
.sucessfullIcon {color: #31871E;
  svg {width: 82px; height: 82px;}
}

.dbHeader {background: $darkBg; height: 80px; border-bottom: 1px solid #2B2B2B; width: 100%; position: fixed; left: 0; top: 0; z-index: 100;}
.dbLogo {width: 250px; height: 80px; padding: 10px 20px; border-right: 1px solid #2B2B2B;
  @include respond-to(media-md) {padding: 10px 15px;}
}
.dbUser {height: 80px; 
  @include respond-to(media-md) {margin-right: 50px;}
  a {font-size: 16px; padding-right: 25px;
    img {width: 40px; height: 40px;}
    &:before {font-family: 'Material Icons'; display: none; content: "expand_more"; font-size:22px; color: #fff; position: absolute; right: 0; top: 8px;}
  }
}

a.showdbMenu {display: none;
    @include respond-to(media-md) {display: block; width: 44px; height: 34px; background: $primary; border-radius: 3px; padding-top: 9px; position: fixed; top: 22px; right: 15px; z-index: 101;
      i {width: 24px; height: 2px; background: #fff; display: block; margin: 0 auto 5px auto; transition:ease-in-out .3s;}
      &.active {
        i {
          &:nth-child(1)  {transform: rotate(45deg); margin: 7px auto 0 auto;}
          &:nth-child(2)  {transform: rotate(-45deg); margin:-1px auto 0 auto;}
          &:nth-child(3)  {display: none;}
        }
      }
    }
  }

  .dbMenuBg {position: fixed; left: 0; top: 0; width: 100%; height: 100%; background: rgba(0,0,0,.9); display: none;
    &.active {display: block;}
  }

.dbMenu {background: $darkBg; height: calc(100% - 80px); border-right: 1px solid #2B2B2B; width: 250px; position: fixed; left: 0; bottom: 0; z-index: 100; padding: 20px 0; overflow: auto;
  @include respond-to(media-md) {left: -250px; transition: ease-in-out .4s;
    &.active {left: 0;}
  }
  ul {
    li {
      a {display: block; color: $grayClr; height: 60px; font-size: 17px; padding: 0 20px; display: flex; align-items: center;
        &:hover, &.active {background: $primary; color: #fff;
          img {opacity: 1;}
        }
        img {opacity: .6;}
      }
    }
  }
}


.dbMid {padding: 80px 0 0 250px;
  @include respond-to(media-md) {padding: 80px 0 0 0px;}
}
.dbRight {padding: 20px;
  @include respond-to(media-md) {padding: 15px;}
}
.dbTop {margin: -10px;
  @include respond-to(media-md) {margin: -5px;}
  li {padding: 10px;
    @include respond-to(media-md) {padding: 5px;}
    .inner {background: #0A0A0C; height: 100%; border-radius: 6px; padding: 20px;
      .img {min-width: 60px; width: 60px;}
      .hd {font-size: 24px;
        span {color: $grayClr; font-size: 16px;} 
      }
      .value {color: $grayClr; font-size: 15px;
        @include respond-to(media-xl) {font-size: 14px;}
        span {height: 30px; border: 1px solid #414141; border-radius: 3px;
          svg {width: 16px; height: 16px;}
          &.up {
            svg {color: #31871E;}
          }
          &.down {
            svg {color: red; transform: rotate(180deg);}
          }
        }
      }
    }
  }
}
.dbBot {margin: -10px; padding-top: 10px;
  @include respond-to(media-md) {margin: -5px;}
  > div {padding: 10px;
    @include respond-to(media-md) {padding: 5px;}
    .inner {background: #0A0A0C; border-radius: 6px;}
  }
}
.dbHd {font-size: 18px;}
a.viewAll {font-size: 15px; color: #0077FF;}
.dbList {margin: 0 15px;
  li {border-bottom: 1px solid #1c1c1c; padding: 15px 0;
    &:last-child {border: none;}
    .det {
      .img {
        img {width:50px; height: 50px;}
      }
      .info {font-size: 16px;
        span {font-size: 14px; color: $grayClr;}
      }
    }
    .time {font-size: 16px; color: $grayClr;}
  }
}

.dbMainHd {font-size: 22px;
  @include respond-to(media-sm) {font-size: 19px;}
  span {height: 40px; width: 40px; background: #fff;
    @include respond-to(media-sm) {height: 36px; width: 36px;}
  }
}
.dbTable {width: 100%; overflow: auto;
  table {width: 100%;
    th {background: #000 ; color: $grayClr; font-size: 16px; font-weight: 400; padding: 15px; border-bottom: none; white-space: nowrap;
      @include respond-to(media-md) {font-size: 15px;}
    }
    td {background: $darkBg; font-size: 16px; padding:20px 15px; border:none;
      @include respond-to(media-md) {font-size: 15px;}
    }
    tr {
      &:nth-child(2n) {
        td {background: #0A0A0C;}
      }
      &:hover {
        td {opacity: .8;}
      }
    }
  }
}
.cursor-pointer {cursor: pointer;}
.dbTable::-webkit-scrollbar:horizontal{height: 1px; background-color: #000;}
.dbTable::-webkit-scrollbar-thumb:horizontal{background: #000; border-radius: 0;}
.paging {
  ul {background: $darkBg; border: 1px solid #2B2B2B;
    li {border-right: 1px solid #2B2B2B;
      &:last-child {border: none;}
      a {color:$grayClr; font-size: 16px; display: block; padding: 10px 15px;
        @include respond-to(media-sm) {font-size: 15px; padding: 10px 12px;}
        &:hover {background: #fff; color: #000;}
      }
      &.active {
        a {background: #fff; color: #000;}
      }
    }
  }
}
.backBtn {background: #fff; min-width: inherit; height: 30px; font-size: 14px; color: #000; padding: 0 15px; border-radius: 4px;
  svg {width: 20px; height: 20px;}
}
.userDetBox {background: $darkBg; border-radius: 10px; padding: 20px;
  @include respond-to(media-sm) {padding: 15px;}
  .left {
    .img {
      img {width: 120px; height: 120px; min-width: 120px; border: 5px solid #151618;
        @include respond-to(media-sm) {width: 80px; height: 80px; min-width: 80px;}
      }
    }
    .name {font-size: 28px;
      @include respond-to(media-xl) {font-size: 24px;}
      @include respond-to(media-sm) {font-size: 18px;}
    }
    .info {margin: -8px -20px;
      @include respond-to(media-xs) {margin: -5px -10px;}
      li {color:$grayClr; font-size: 18px; padding: 8px 20px;
        @include respond-to(media-xl) {font-size: 16px;}
        @include respond-to(media-sm) {font-size: 14px;}
        @include respond-to(media-xs) {width: 50%; padding: 5px 10px;}
        span {font-size: 28px; color: $whtClr;
          @include respond-to(media-xl) {font-size: 24px;}
          @include respond-to(media-sm) {font-size: 18px;}
        }
        .barBox {padding-left: 32px;
          @include respond-to(media-xl) {padding-left: 24px;}
          @include respond-to(media-sm) {padding-left: 18px;}
          img {position: absolute; left: 0; top: 4px; width: 22px;
            @include respond-to(media-xl) {top: 6px; width: 16px;}
            @include respond-to(media-sm) {width: 12px;}
          }
        }
      }
    }
  }
  .right {
    @include respond-to(media-lg) {padding: 20px 0 0 135px;}
    @include respond-to(media-sm) {padding: 20px 0 0 95px;}
    @include respond-to(media-xs) {padding: 20px 0 0 0;}
    .btn {background: #303030; padding: 0 15px; height: 42px; font-size: 16px; border: none !important;
      @include respond-to(media-sm) {font-size: 14px;}
      &:hover {border: none;}
    }
  }
}

.nftDetail {
  .img {width: 400px;
    @include respond-to(media-lg) {width: 300px;}
    @include respond-to(media-sm) {width: 100%; max-width: 400px;}
    img {width: 100%; border: 6px solid #242424; border-radius: 6px;}
    .rotate {position: absolute; right: 20px; top: 20px; cursor: pointer;
      img {border:none;}
    }
  }
  .det {width: calc(100% - 400px);
    @include respond-to(media-lg) {width: calc(100% - 300px);}
    @include respond-to(media-sm) {width: 100%;}
    &.std {width: 100%; background: $darkBg;}
    ul {
      li {border-top:1px solid #2a2a2a; font-size: 16px; line-height: 22px;
        &.desc {
          @include respond-to(media-sm) {display: block !important;}
          .left {
            @include respond-to(media-sm) {min-width: 100%; display: block; padding-bottom: 4px;}
          }
        }
        @include respond-to(media-lg) {font-size: 15px; line-height: 20px;}
        &:first-child {padding-top: 0 !important; border-top: none;
          @include respond-to(media-sm) {padding-top: 18px !important;}
        }
        .left {color: $grayClr; min-width: 160px;
          @include respond-to(media-lg) {min-width: 110px;}
        }
      }
    }
  }
}

.detailTab {display: flex;  
    a {background: #242424; border-radius: 6px 6px 0 0; margin-right: 5px; display: block; padding: 15px 10px; font-size: 18px; color: $grayClr; min-width: 160px; text-align: center;
      @include respond-to(media-sm) {min-width: 100px; font-size: 16px; padding:10px;}
      &:hover, &.active {background: #fff; color: #000;}
    }
}

.detList {background: $darkBg; border-radius:6px; padding: 20px;
  @include respond-to(media-sm) {padding: 10px;}
  ul { margin: -5px;
  li {padding: 5px; width: 16.66%; font-size: 14px;
    @include respond-to(media-xl) {width: 20%;}
    @include respond-to(media-lg) {width: 25%;}
    @include respond-to(media-sm) {width: 33.33%;}
    @include respond-to(media-xs) {width: 50%;}
    .inner {background: #151618; border: 1px solid #3B3B3B; height: 100%;
      .mid {font-size: 16px;}
    }
  }
}
}
.dbTableR {border-radius: 0 8px 8px 8px;}

.tooltipBox {background: #fff !important; z-index: 100; padding:10px !important; opacity: 1 !important; color: #000 !important; min-width: 150px; text-align: center;
  &:after {border-top-color: #fff !important;}
}


.supportTicketDet { background: $darkBg;
    ul {
      li {border-top:1px solid #2a2a2a; font-size: 16px; line-height: 22px; word-break: break-word;
        &.message {
          @include respond-to(media-sm) {display: block !important;}
          .left {
            @include respond-to(media-sm) {min-width: 100%; display: block; padding-bottom: 4px;}
          }
        }
        @include respond-to(media-lg) {font-size: 15px; line-height: 20px;}
        &:first-child {border-top: none;}
        .left {color: $grayClr; min-width: 160px;
          @include respond-to(media-lg) {min-width: 110px;}          
        }
      }
    }
}
.btn {
  &.closeTicket {background: #303030; border-color: #303030;}
}

.settingForm {background: $darkBg;
  .form {max-width: 700px;}
}

.leftForm {width: 60%;}
.rightForm {width: 40%; max-width: 300px !important;}

.toggleBtn {
  .css-5ryogn-MuiButtonBase-root-MuiSwitch-switchBase.Mui-checked {color: #31871E;}
  .css-1yjjitx-MuiSwitch-track {background: #fff;}
  .css-5ryogn-MuiButtonBase-root-MuiSwitch-switchBase.Mui-checked+.MuiSwitch-track {background: #fff;}
}