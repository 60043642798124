@import '../assets/css/mixin.scss';
.checkoutLeft {width: calc(68% - 40px);
    @include respond-to(media-lg) {width: calc(60% - 20px);}
    @include respond-to(media-md) {width:100%;}
}
.checkoutRight { width: 32%;
    @include respond-to(media-lg) {width: 40%;}
    @include respond-to(media-md) {width:100%;}
}

.checkoutInner {background: $lightBg;}
.shippingPrice {
    li {font-size:17px;
        @include respond-to(media-sm) {font-size: 15px;}
    }
}
.orederDet {
    .det {
        .img {width: 72px; 
            @include respond-to(media-sm) {width: 52px; }
            img {width: 72px; height: 72px; min-width: 72px;
                @include respond-to(media-sm) {width: 52px; height: 52px; min-width: 52px;}
            }
        }
        .address {font-size: 16px; line-height: 22px;
            @include respond-to(media-sm) {font-size:15px; line-height: 20px;}
        }
    }
    .right {font-size: 20px;
        .icon {width: 16px; height: 16px; background: $whtClr;
            @include respond-to(media-sm) {font-size:15px;}
            img {width: 8px;}
        }
        .price {font-size: 14px; color: $grayClr;
            @include respond-to(media-sm) {font-size:13px;}
        }
    }
}
.priceList {
    li {border-top:1px solid rgba(255,255,255,.2); font-size: 18px; color: $grayClr;
        @include respond-to(media-sm) {font-size: 16px;}
        &.total {color:$whtClr; font-size: 22px;
            @include respond-to(media-sm) {font-size: 20px;}
        }
    }
}
a.trackShipment {color: #627EEA; text-decoration: underline;}


.partnerList {margin: -10px;
    li {width: 25%; padding: 10px;
        @include respond-to(media-xs) {width: 50%;}
        label {display: block; position: relative;
            img {width: 100%;}
            .radio {position: absolute; right: 15px; top: 15px;}
        }
    }
}