@import '../assets/css/mixin.scss';
.profileBanner {background-size: cover; min-height: 250px;
  @include respond-to(media-sm) {min-height: 150px;}
  img {width: 100%; opacity: 0;}
}
.profileTop {
  .userImg { position: relative; margin-top: -60px;
    @include respond-to(media-sm) {margin-top: -30px;}
    img {border-radius: 100%; border: 5px solid #000; width: 150px; height: 150px;
      @include respond-to(media-sm) {width: 100px; height: 100px;}
    }
  }
  .userName {font-size: 28px;
    @include respond-to(media-sm) {font-size: 22px;}
    .check {width: 20px; height: 20px; color: #AEDE0A;}
  }
  .userId {background: #242424; height: 40px; border-radius: 30px;
    .bar {width: 28px; height: 28px; line-height: 26px; background: #fff; margin: 6px; border-radius: 100%;
      img {width: 12px;}
    }
    .code {font-size: 14px; line-height: 40px;}
    .copy {
      svg {width: 18px; height: 18px;}
    }
  }
  .follow {font-size: 16px;
    span {color: $grayClr;}
  }
  .right {padding: 80px 0 0 0;
    @include respond-to(media-md) {padding: 20px 0 0 0;}
  }
  .profileSocial {margin: -5px;
    li {padding: 5px;
      a {display: block; width: 50px; height: 50px; border-radius: 5px; display: flex; align-items: center; justify-content: center;
        @include respond-to(media-lg) {width: 42px; height: 42px;}
        @include respond-to(media-sm) {width: 32px; height: 32px; border-radius: 3px;}
        &:hover {background:$primary !important;}
        img {
          @include respond-to(media-sm) {width: 16px;}
        }
      }
      &.share {        
        a {background:#303030 !important;
          img {
            @include respond-to(media-sm) {width: 16px;}
          }
        }
      }
      &.follow {
        a {background: $whtClr; color: #000; width: auto; padding: 0 20px;
          @include respond-to(media-sm) {padding: 0 10px; font-size: 14px;}
          &:hover {background: $whtClr !important;}
        }
      }
      &.fav {position: relative;
        &:before {content: ''; background: #666; width: 1px; height: 20px; position: absolute; left: 0; top: 50%; margin-top: -10px;}
        svg {
          @include respond-to(media-sm) {width: 18px; height: 18px;}
        }
      }
    }
  }
  .info {margin: -8px -20px;
    @include respond-to(media-sm) {margin: -5px -10px;}
    li {color:$grayClr; font-size: 18px; padding: 8px 20px;
      @include respond-to(media-xl) {font-size: 16px;}
      @include respond-to(media-sm) {font-size: 14px;}
      @include respond-to(media-sm) {width: 33.33%; padding: 5px 10px;}
      span {font-size: 28px; color: $whtClr;
        @include respond-to(media-xl) {font-size: 24px;}
        @include respond-to(media-sm) {font-size: 18px;}
      }
      .barBox {padding-left: 32px;
        @include respond-to(media-xl) {padding-left: 24px;}
        @include respond-to(media-sm) {padding-left: 18px;}
        img {position: absolute; left: 0; top: 4px; width: 22px;
          @include respond-to(media-xl) {top: 6px; width: 16px;}
          @include respond-to(media-sm) {width: 12px;}
        }
      }
    }
  }
}

.productList {margin: -8px;
  li {width: 25%; padding: 8px;
    @include respond-to(media-md) {width: 33.33%;}
    @include respond-to(media-sm) {width: 50%;}
    @include respond-to(media-xs) {width: 100%;}
    .inner {background: #242424; height: 100%; border-radius: 10px; padding: 10px;
      @include respond-to(media-lg) {padding: 5px;}
      .img {position: relative; overflow: hidden;
        
        a {
          img {border-radius: 10px; width: 100%; transition: ease-in-out .4s;}
          &:hover {
            img {transform: scale(1.1);}
          }
        }
        .rotate {width: 50px; position: absolute; right: 15px; top: 15px; cursor: pointer;}
        .item {position: absolute; left: 15px; bottom: 15px; background:$primary; border-radius: 6px; font-size: 16px; padding: 6px 10px;}
      }
      .userName {font-size: 18px; padding: 12px 15px;
        span {font-size: 16px; color: #B5B5B5;}
      }
      .price {border: 1px solid #343434; border-radius: 10px; font-size: 15px; color: #B5B5B5; padding: 5px 15px 10px 15px;
        span {color: #fff; font-size: 16px;}
      }
    }
  }
}