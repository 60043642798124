@import '../assets/css/mixin.scss';
.bannerCon {background-size: cover !important; position: relative; overflow: hidden;
    .container {min-height: calc(100vh - 70px); position: relative; z-index: 100;
      @include respond-to(media-lg) {min-height: 500px;}
      @include respond-to(media-xs) {min-height: 350px;}
    }
    .bannerIner {color: #fff; max-width: 700px;
      @include respond-to(media-md) {max-width: 600px;}
      .bannerHd {font-size: 75px; line-height: 90px; font-family: $primary-font; font-weight: 500;
        @include respond-to(media-lg) {font-size: 65px; line-height: 75px;}
        @include respond-to(media-md) {font-size: 55px; line-height: 65px;}
        @include respond-to(media-xs) {font-size: 34px; line-height: 42px;}
      }
      .bannerText {color: #BBBBBB; font-size: 22px; line-height: 32px;
        @include respond-to(media-lg) {font-size: 19px; line-height:30px;}
        @include respond-to(media-xs) {font-size: 17px; line-height:28px;}
      }
      a.exploreLink {color: #fff; font-size: 22px; line-height: 32px;
        @include respond-to(media-lg) {font-size: 19px; line-height:30px;}
        &:hover {color: $primary;}
      }
    }
}

.bannerVideoBox {width: 100%; position: absolute; left: 0; bottom: 0;
  &:before {content: ''; width: 100%; height: 100%; background: rgba(0,0,0,.5); z-index: 2; position: absolute; left: 0; top: 0;}
  .videoInner {position: relative; padding-bottom: 56.25%;
    video {position: absolute; top: 0; left: 0; width: 100%; height: 100%;}
  }
}