@import '../assets/css/mixin.scss';
.topCollList {background: #1A1B1D; border: 1px solid #2E2E2E; border-radius: 10px; overflow: hidden;
  li {border-top: 1px solid #2E2E2E; cursor: pointer;
    @include respond-to(media-md) {font-size: 15px;}
    @include respond-to(media-sm) {font-size: 13px;}
    &:first-child {border: none;}
    &:hover {background: #2E2E2E;}
    > div {padding: 20px 30px;
      @include respond-to(media-md) {padding: 20px 15px;}
      @include respond-to(media-sm) {padding: 10px;}
    }
    .collectionInfo {font-size: 18px;
      @include respond-to(media-md) {font-size: 16px;}
      @include respond-to(media-sm) {font-size: 15px;}
      .img {width: 70px; height: 70px; position: relative;
        @include respond-to(media-sm) {width: 40px; height: 40px;}
        img {width: 70px; height: 70px; min-width: 70px; border-radius: 100%;
          @include respond-to(media-sm) {width: 40px; height: 40px; min-width: 40px;}
        }
        .check {color: #AEDE0A; position: absolute; right: 0; bottom: 0; background: #1A1B1D; border-radius: 100%; width: 20px; height: 20px; box-shadow: 0 0 0 3px #AEDE0A inset;
          @include respond-to(media-sm) {width: 16px; height: 16px;}
        }
      }
    }
  }
}

.tcHdBox {
  > div {padding: 0 30px;
    @include respond-to(media-md) {padding: 0 15px;}
    @include respond-to(media-sm) {padding:0 10px;}
  }
}