@import '../assets/css/mixin.scss';
.videoBox {max-width: 1000px; width: 100%; margin: auto;
  @include respond-to(media-lg) {max-width: 800px;}
  .videoLeftImg {position: absolute; left: -320px; bottom: 0;
    @include respond-to(media-lg) {left: -160px; width: 220px;}
  }
  .videoRightImg {position: absolute; right: -130px; bottom: 0;
    @include respond-to(media-lg) {right: -80px; width: 70px;}
  }
  .video {width:100%; position: relative; padding-bottom: 56.25%;
    .play {position: absolute; left: 0; top: 0; right: 0; bottom: 0; margin: auto; width: 70px; height: 52px;}
    iframe {position: absolute; top: 0; left: 0; width: 100%; height: 100%; border:none; vertical-align:top;}
  }
}

.mailingList {border-radius: 10px; padding: 20px; height: 400px;
  @include respond-to(media-lg) {height: 300px;}
  @include respond-to(media-xs) {height: auto; padding: 20px 40px;}
  .inner {max-width: 700px; width: 100%; margin: auto;
    .mainHd {position: relative; z-index: 10;}
    .mailingImg {position: absolute; left: 50px; top: 50%; margin-top: -130px;
      @include respond-to(media-xs) {width: 60px; margin-top: -50px; left: 0;}
    }
    .eamilerBox {padding-right: 150px;
      @include respond-to(media-xs) {padding-right: 0;}
      .emailerInput {width: 100%; height: 60px; font-size: 16px; border-radius: 10px; border: none; padding: 0 20px;
        @include respond-to(media-xs) {height: 50px;}
      }
      .subscribeBtn {width: 130px; position: absolute; right: 0; top: 0; height: 100%; background: $primary; color: #fff; border: none; border-radius: 10px; font-size: 18px;
        @include respond-to(media-xs) {position: static; margin-top: 10px; width: 100%; padding: 12px;}
        &:hover {opacity: .8;} 
       }
    }
  }
}

.mainCon {
  &.home {
    .footerCon {background: #0f0708 !important; padding-top:50px;}
  }
}
