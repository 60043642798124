@import '../assets/css/mixin.scss';
.profileDet {width: calc(100% - 380px); padding-left: 50px; min-height: 150px;
  @include respond-to(media-lg) {width: calc(100% - 300px); padding-left: 30px;}
  @include respond-to(media-md) {width: 100%; padding-left: 0;}
}
.profileForm {width: calc(100% - 260px); max-width: 760px; padding-right: 50px;
  @include respond-to(media-lg) {width: calc(100% - 200px); padding-right: 30px;}
  @include respond-to(media-sm) {width: 100%; padding:20px 0 0 0;}
}

.profileImg {width: 260px;
  @include respond-to(media-lg) {width: 200px;}
}
.note {color: #BBBBBB; font-size: 14px;}
.chhoseFile {width: 100%; background: #000; border-color: #000; overflow: hidden; position: relative;
  .file {position: absolute; left: 0; top: 0; font-size: 50px; opacity: 0;}
}
.noteText {color: $grayClr; font-size: 18px; line-height: 26px;
  @include respond-to(media-lg) {font-size: 16px; line-height: 22px;}
}

.notifiationList {border: 1px solid #414141; border-radius: 10px; max-width: 1000px;
  li {border-bottom: 1px solid #414141; padding: 15px 20px 15px 70px; color: $grayClr; font-size: 18px; position: relative;
    @include respond-to(media-lg) {font-size: 16px;}
    @include respond-to(media-sm) {padding: 12px 12px 12px 60px; font-size: 15px;}
    span {font-size: 22px; color: #fff;
      @include respond-to(media-lg) {font-size: 20px;}
      @include respond-to(media-sm) {font-size: 18px;}
    }
    &:last-child {border-bottom: none;}
    .checkbox {position: absolute; left: 25px; top: 50%; margin-top: -12px;
      @include respond-to(media-sm) {left: 15px;}
    }
  }
}
.subHd {font-size: 22px; line-height: 30px;
  @include respond-to(media-lg) {font-size: 20px; line-height: 26px;}
}
.currencyBox {background: #242424; border: 1px solid #3B3B3B; border-radius: 10px; font-size: 15px; max-width: 1000px;
  .currency {padding: 5px 20px; color: $grayClr;
    @include respond-to(media-sm) {padding: 5px 15px;}
    span {width:32px; height:32px; border-radius: 100%; background: #627EEA;}
  }
  .price {border-left: 1px solid #3B3B3B; padding: 5px 20px; min-height: 55px;
    @include respond-to(media-sm) {padding: 5px 15px;}
  }
}

.viewOffer {background: #151618; border: 1px solid #707070; padding: 10px 35px;
  @include respond-to(media-sm) {padding: 10px 25px;}
}

.paymentList {border: 1px solid #414141; border-radius: 10px; max-width: 1000px;
  > li {border-bottom: 1px solid #414141; padding: 15px 20px 15px 90px; color: $grayClr; font-size: 16px; position: relative; counter-increment: item;
    @include respond-to(media-sm) {padding: 15px 10px 15px 60px; font-size: 15px;}
    &:before {content: counter(item); border-radius: 100%; width: 44px; height: 44px; background: #151618; border: 1px solid #FFFFFF; position: absolute; left: 20px; top: 20px; font-size: 20px; text-align: center; line-height: 42px; color: #fff;
      @include respond-to(media-sm) {width: 32px; height: 32px; font-size: 16px; line-height: 28px; left: 10px;}
    }
    span {font-size: 20px; color: #fff;
      @include respond-to(media-sm) {font-size: 18px;}
    }
    &:last-child {border-bottom: none;}
    .list {
      li {position: relative; padding: 2px 0 2px 10px;
        &:before {content: ''; width: 4px; height: 4px; background: $grayClr; border-radius: 100%; position: absolute; left: 0; top: 12px;
          @include respond-to(media-sm) {top: 11px;}
        }
      }
    }
    .btn {background: #303030; border-color: #303030;
      &:hover {background: $primary !important; color: #fff !important; border-color: $primary !important;}
    }
  }
}
.collection {
  a {
    &:hover {color: $primary !important;}
  }
  img {width: 42px; height: 42px;}
}