@import '../assets/css/mixin.scss';
.profileMenu {width: 380px;
  @include respond-to(media-lg) {width: 300px;}
  @include respond-to(media-md) {width: 100%; text-align: right;}
  ul {
    @include respond-to(media-md) {left: -300px; transition: ease-in-out .4s; position: fixed; top: 0; z-index: 1000; width: 300px; height: 100%; overflow: auto; background: #000; padding: 15px !important;
      &.active {position: fixed; left: 0; padding: 15px !important;}
    }
    li {padding-bottom: 8px;
      a {background: #242424; height: 60px; padding: 0 20px; display: flex; align-items: center; border-radius: 10px; color: #AEAEAE !important; font-size: 18px; text-align: left;
        @include respond-to(media-md) {height: 50px; font-size: 16px;}
        &:hover, &.active {background: $primary; color: #fff !important;}
        svg {margin-right: 10px;}
      }
    }
  }
}

a.showMenu {display: none;
  @include respond-to(media-md) {display: inline-block; margin-bottom: 5px; background: $primary; border-radius: 4px; padding: 8px 15px;
    .hide {display: none;}
    &.active{
      .show {display: none;}
      .hide {display: inline;}
    }
  }
}

.bgCover {position: fixed; opacity: 0; left: 0;  top: 0; width: 100%; height: 100%; z-index: 999; background: #000; display: none;
  &.active {display: block;}
}