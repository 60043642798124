@import url('https://fonts.googleapis.com/css2?family=Roboto:wght@400;500;700&display=swap');
@import url('https://fonts.googleapis.com/css2?family=DM+Serif+Display&display=swap');
@import './mixin.scss';


* {margin:0; padding:0; outline:0; -webkit-box-sizing:border-box; box-sizing:border-box; list-style:none;}
body {font-size:16px; line-height:normal; color: #fff; font-family: 'Roboto', sans-serif; background: #151618;
  @include respond-to(media-sm) {font-size: 15px;} 
}
a {outline:none; text-decoration:none; color:#fff; -webkit-transition:ease-in-out .3s; -moz-transition:ease-in-out .3s; -ms-transition:ease-in-out .3s; -o-transition:ease-in-out .3s; transition:ease-in-out .3s; cursor: pointer;}
a:hover {text-decoration:none; color: #fff;}
input, textarea, select {font-size:14px; color:#000; vertical-align:middle; font-family: 'Roboto', sans-serif; outline:none; resize:none;}
input[type="button"], input[type="submit"], button {-webkit-transition:ease-in-out .3s; -moz-transition:ease-in-out .3s; -ms-transition:ease-in-out .3s; -o-transition:ease-in-out .3s; transition:ease-in-out .3s; -moz-appearance:none; -webkit-appearance:none; appearance:none; cursor: pointer;}
select {appearance: none; -ms-appearance: none; -webkit-appearance: none;}
select::-ms-expand {display: none;}
.transition {-webkit-transition:ease-in-out .5s; -moz-transition:ease-in-out .5s; -ms-transition:ease-in-out .5s; -o-transition:ease-in-out .5s; transition:ease-in-out .5s;}
img {max-width:100%;}
h1, h2, h3, h4, h5, h6 {font-weight: normal;}
.mainCon {width:100%; position:relative; min-height:100vh; padding-top: 70px;
  @include respond-to(media-lg) {padding-top: 70px;}
  @include respond-to(media-xs) {padding-top: 115px;}
  &.blogCon {
    @include respond-to(media-xs) {padding-top: 70px;}
  }
}
.container {max-width:1700px; width: 100%; margin:auto; padding:0 30px;
  @include respond-to(media-lg) {padding:0 15px;}
}
.grayBg {background: #0a0a0c;}
.largeHd {font-size: 75px; line-height: 95px; font-family: $primary-font; margin-top: -8px;
  @include respond-to(media-xl) {font-size: 65px; line-height: 85px;}
  @include respond-to(media-lg) {font-size: 50px; line-height: 65px;}
  @include respond-to(media-sm) {font-size: 32px; line-height: 42px;}
}
.mainHd {font-size: 50px; color: #fff; font-family: $primary-font; margin-top: -8px;
  @include respond-to(media-lg) {font-size: 40px;}
  @include respond-to(media-sm) {font-size: 28px;}
}
.btn {font-size: 18px; min-width:100px; text-align: center; color: #fff; padding: 0 20px; background: $primary; border: 1px solid $primary; height: 50px; line-height: normal; display: inline-flex; justify-content: center; align-items: center;
  @include respond-to(media-sm) {font-size: 16px;}
   &:hover {background: #fff !important; border: 1px solid #fff !important; color: $primary !important;}
}

.slick-track{margin: 0;}

.bottomArrow {
   .slick-list {margin: 0 -10px; display: flex;
    @include respond-to(media-lg) {margin: 0 -5px;}
     .slick-track{display: flex; margin: 0;
       .slick-slide {padding: 0 10px; height: 100%;
        @include respond-to(media-lg) {padding: 0 5px;}
         > div {height: 100%;}
       }
     }
     
   }
   .slick-arrow {position: absolute; opacity: 1; z-index: 20; width: 50px; height: 50px; background: #fff; border-radius: 100%; top: inherit; bottom: -95px;
    @include respond-to(media-sm) {width: 40px; height: 40px; bottom: -85px;}
     &:hover {background: #fff; opacity: .8;}
     &.slick-disabled {background: #fff; opacity: .8; cursor: not-allowed;}
     
     &.slick-prev {right: 60px; left: inherit;
      @include respond-to(media-sm) {right: 50px;}
       &:before {font-family: 'Material Icons'; content: "arrow_left"; font-size: 32px; color: #000;}
     }
     &.slick-next {right: 0;
       &:before {font-family: 'Material Icons'; content: "arrow_right"; font-size:32px; color: #000;}
     }
   }
 }

::-webkit-scrollbar {width: 5px;}
::-webkit-scrollbar-track { background: #151618; }
::-webkit-scrollbar-thumb { background: #888; }
::-webkit-scrollbar-thumb:hover { background: #555;}

.tabs {overflow: auto; flex-wrap: nowrap;
    a {font-size: 16px; display: block; padding: 10px 25px; position: relative; white-space: nowrap; color: $whtClr;
      @include respond-to(media-sm) {font-size: 15px; padding: 10px 18px;}  
      span {position: relative; z-index: 2; color: $whtClr;}
      &.active, &:hover {color: $whtClr;
        &:before {content: ''; background: transparent linear-gradient(180deg, #A020F000 0%, #A020F0 100%); position: absolute; left: 0; top: 0; width: 100%; height: 100%; opacity: .3;}
        &:after {content: ''; width: 100%; height: 1px; background: rgba(255,255,255,.5); position: absolute; left: 0; bottom: 0px;}
      }
    }
}
.tabs::-webkit-scrollbar:horizontal{
  height: 1px;
  background-color: #000;
}
.tabs::-webkit-scrollbar-thumb:horizontal{
        background: #000;
        border-radius: 0;
        
    }

  .popupBox { height:100%; overflow: auto;
    .modal-dialog {max-width: 600px; height: 100%; margin: auto; padding: 20px; display: flex;   flex-flow: row wrap; align-items: center;}
    .modal-content {background: $lightBg; padding: 30px; position: relative;
      @include respond-to(media-sm) {padding: 10px;}  
      .close {position: absolute; right: 8px; top: 8px; color: #fff;}
      .modal-body {position: static; max-width: 450px; width: 100%; margin: auto;}
      .popupHd {font-size: 40px; font-family: $primary-font;
        @include respond-to(media-lg) {font-size: 34px;}
        @include respond-to(media-sm) {font-size: 26px;}        
      }
    }
  }
  .form {margin: -10px;
    @include respond-to(media-xs) {margin: -8px -5px;}
    li {padding: 10px;
      @include respond-to(media-xs) {padding:8px 5px;}
      .full {width: 100%;}
      .btn {min-width: 160px;}
    }
  }
  .imgBox {background: #242424 url(../images/gallery_icon.png) no-repeat center; overflow: hidden; border: 1px dashed #676767;}
  .formInput {width: 100%; background: #242424; border: 1px solid #3B3B3B; border-radius: 10px; height: 52px; color: #fff; font-size: 17px; padding: 0 15px;
    @include respond-to(media-sm) {height: 42px; font-size: 15px; border-radius: 6px;}
    &.textarea {height: auto; padding: 15px;}  
  } 
  .fieldHd {font-size: 15px;
    .small {font-size: 13px; color: #BBBBBB; max-width: 700px;}
  }
  .selectBox {position: relative;
    .formInput {padding-right: 30px; position: relative; background:#242424 url(../images/select_arrow.png) no-repeat right 5px center;}
  }
  .checkbox {
  .form-check-input {width: 24px; height: 24px; margin: 0; border-radius: 1px; background: #313030; border: 1px solid #707070;
    @include respond-to(media-sm) {width: 20px; height: 20px;}
    &:checked {background-color: $primary; border-color: $primary;}
  }
}
.radioBox {
  .form-check-input {width: 20px; height: 20px; margin: 0; border-radius: 100%; background: #313030; border: 1px solid #707070;
    @include respond-to(media-sm) {width: 20px; height: 20px;}
    &:checked {background-color: $primary; border-color: $primary;}
  }
}

.table {width: 100%; overflow: auto; border: 1px solid #3B3B3B; border-radius: 10px 10px 0 0;
  table {width: 100%;
    th {background: #242424 ; color: #fff; font-size: 16px; font-weight: 400; padding: 15px; border-bottom: none; white-space: nowrap;
      @include respond-to(media-md) {font-size: 15px;}
    }
    td {color: #fff; font-size: 16px; padding: 15px; border-bottom: none; border-top: 1px solid #3B3B3B;
      @include respond-to(media-md) {font-size: 15px;}
    }
  }
}
.table::-webkit-scrollbar:horizontal{height: 1px; background-color: #000;}
.table::-webkit-scrollbar-thumb:horizontal{background: #000; border-radius: 0;}
.noResult {font-size: 30px;
    @include respond-to(media-lg) {font-size: 26px;}
    @include respond-to(media-sm) {font-size: 20px;}   
}

.search {height: 40px; border: 1px solid #474747; border-radius: 10px; width: 500px; position: relative;
  .input {width: 100%; height: 40px; padding: 0 50px 0 15px; background: none; border: none; color: #fff;}
  .searchIcon {position: absolute; right: 10px; top: 50%; margin-top: -12px;
    svg {width: 24px; height: 24px;}
  }
  @include respond-to(media-md) {width: 400px;}
  @include respond-to(media-sm) {width: 100%;}
}
a.filter {background: #0A0A0C; border: 1px solid #2A2A2A; border-radius: 10px; width: 40px; height: 40px; color: $grayClr;}
.itemImg {width: 50px; min-width: 50px; height: 50px; border-radius: 4px;}
.expected {color: $grayClr; font-size: 14px;}
.status {border-radius: 4px; height: 26px; line-height: 26px; padding: 0 10px; font-size: 13px;
  &.processing {background: #EE6751;}
  &.delivered {background: #31871E;}
  &.open {background: #5170EE;}
  &.solved {background: #31871E;}
  &.closed {background: #3C434C;}
  &.dispatched {background: #5170EE;}
}
.placeOrder {white-space: nowrap; background: #303030; border: 1px solid #303030;}
.grayClr {color: $grayClr !important;}
.blueClr {color: #206AE4;}
.font14 {font-size: 14px;}
.attachText {
  svg {transform:rotate(-60deg);}
}
.greenClr {color: $primary;}

.choosePhoto {background: #206AE4; position: relative; margin-top: 10px; color: #fff; display: flex; align-items: center; justify-content: center; width: 160px; height: 44px; overflow: hidden; border-radius: 4px;
  .file {position: absolute; left: 0; top: 0; font-size: 44px; opacity: 0;}
}
.formBox { margin-top: 12px; 
.inner {position: relative;display: flex; padding-right: 55px;
  .formInput {background: #fff; color: #000;}
  .remove {margin-left: 5px;
    button {background: red; color: #fff; height: 100%; width: 48px; border: none; border-radius: 6px;}
  }
  .add {margin-left: 5px; position: absolute; right: 0; top: 0; height: 100%;
    button {background: #31871E; color: #fff; height: 100%; width: 48px; border: none; border-radius: 6px;}
  }
}
}

.statsHd {font-size: 19px; font-weight: 600; padding-bottom: 10px;}
.popupForm {margin: 0; padding: 0;
  .formInput {background: #fff; color: #000;}
}

a.button {background: #206AE4; border-radius: 4px; display: inline-block; padding: 6px 10px; color: #fff;}
.pt-large {padding-top: 140px;
  @include respond-to(media-sm) {padding-top: 50px;}
}
.pb-large {padding-bottom: 140px;
  @include respond-to(media-sm) {padding-bottom: 50px;}
}