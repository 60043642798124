@import '../assets/css/mixin.scss';
.collectionForm {max-width: 1000px; position: relative; width: 100%; margin: auto;}
.itemBox {background: #151618; border: 1px solid #3B3B3B; border-radius: 10px; padding: 15px 20px;}
.AddIcon {background: #242424; width: 46px; height: 46px; border-radius: 100%;
    &:hover {background: $primary;}
}
.toggleBtn {
 .form-check {
    .form-check-input {width: 50px; height: 22px; background-color: #7B7B7B; border-color: #7B7B7B;
        &:checked {background-color: $primary; border-color: $primary;}
    }
 }    
}

.uploadBtn {background: #242424; overflow: hidden; color: $grayClr; border-color: #242424;
    .file {position: absolute; left: 0; top: 0; font-size: 30px; opacity: 0;}
}

.loader {position: absolute; left: 50%; bottom: 100px; margin: 0 0 0 -100px;
    svg {width: 200px; height: 200px;}
}