@import '../assets/css/mixin.scss';
.fractionalBanner {background-size: cover !important; background: url(../assets/images/fractional-banner-bg.jpg) no-repeat center; padding: 100px;
  @include respond-to(media-lg) {padding: 40px;}
  @include respond-to(media-sm) {padding: 20px;}
  .left {width: 40%; position: relative;
    @include respond-to(media-xs) {width: 100%;}
    img {width: 100%;
      &.img {border-radius: 14px;}
    }
    .buyNowBtn {position: absolute; left: 20px; bottom: 20px; background: #31871E; font-size: 18px; color: #fff; border-radius: 10px; padding: 12px 50px;}
  }
  .right {width: 60%; font-size: 60px; text-transform: uppercase; color: #fff;
    @include respond-to(media-lg) {font-size: 40px;}
    @include respond-to(media-xs) {width: 100%; font-size: 30px;}
  }
}
.contentBox {font-size: 17px; line-height: 28px;
  @include respond-to(media-sm) {font-size: 16px; line-height: 24px;}
}
.grayClr {color: #A1A1A1;}
.facList {background: url(../assets/images/blurred-bg.jpg) repeat-y; border-radius: 20px;
  li {padding: 30px 20px; font-size: 20px; display: flex; align-items: center;
    @include respond-to(media-md) {padding: 15px; font-size: 17px;}
    @include respond-to(media-md) {padding: 10px 15px;}
    .count {background: #1E1E1E; border: 1px solid #636363; border-radius: 30px; width: 60px; min-width: 60px; height: 60px; display: flex; align-items: center; justify-content: center; margin-right: 10px;
      @include respond-to(media-md) {width: 40px; height: 40px; min-width: 40px;}
    }
  }
}

.blockList {margin: -5px;
  li {padding: 5px;
    .inner {padding: 20px; border-radius: 10px; height: 100%; color: #A1A1A1; font-size: 17px; line-height: 26px; background: #232227 url(../assets/images/texture.png) no-repeat right bottom; background-size: cover;
      @include respond-to(media-sm) {font-size: 16px; line-height: 24px; padding: 10px;}
      span {color: #fff; font-size: 20px;
        @include respond-to(media-sm) {font-size: 18px;}
      }
    }
  }
}

.shipmentList {
  > li {margin-top: 40px; display: flex;
    .icon {min-width: 120px;
      @include respond-to(media-md) {min-width: 100px;
        img {width: 80px;}
      }
      @include respond-to(media-xs) {min-width: 50px;
        img {width: 40px;}
      }
    }
    .det {padding-left: 10px; color: #A1A1A1; font-size: 18px;
      @include respond-to(media-md) {font-size: 16px;}
      span {display: block; font-size: 32px; color: #fff; padding-bottom: 10px;
        @include respond-to(media-md) {font-size: 24px;}
        @include respond-to(media-xs) {font-size: 20px;}
      }
      ul {
        li {position: relative; padding: 5px 0 5px 18px;
          &:before {content: ''; background: #A1A1A1; width: 6px; height: 6px; border-radius: 100%; position: absolute; left: 0; top: 15px;}
        }
      }
    }
  }
}