@import '../assets/css/mixin.scss';
.diseImg {position: absolute; left: -40px; top: -50px;}
.browseCategory {
  .item {border-radius: 10px; overflow: hidden; background: #242424;
    .img {overflow: hidden;
      video {border-radius: 10px 10px 0 0; width: 100%; transition: ease-in-out .4s; vertical-align: top;}
    }
    .name {
      a {display: block; padding: 14px; font-size: 22px; color: #fff;
        @include respond-to(media-lg) {font-size: 19px;}
      }
    }
    &:hover {
      .img {
        a {
            img {transform: scale(1.1);}
        }
      }
      .name {
        a {background: $primary;}
      }
    }
  }
}
