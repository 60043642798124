@import '../assets/css/mixin.scss';
.purchaceDetail {
  .img {width: 600px;
    @include respond-to(media-lg) {width: 450px;}
    @include respond-to(media-md) {width: 300px;}
    @include respond-to(media-sm) {width: 100%; max-width: 400px;}
    img {width: 100%; border: 10px solid #242424; border-radius: 0;}
    .rotate {position: absolute; right: 20px; top: 20px; cursor: pointer;
      img {border:none;}
    }
  }
  .det {width: calc(100% - 600px);
    @include respond-to(media-lg) {width: calc(100% - 450px);}
    @include respond-to(media-md) {width: calc(100% - 300px);}
    @include respond-to(media-sm) {width: 100%;}
    &.std {width: 100%; background: $darkBg;}
    ul {
      li {border-top:1px solid #2a2a2a; font-size: 16px; line-height: 22px;
        &.desc {
          @include respond-to(media-md) {display: block !important;}
          .left {
            @include respond-to(media-md) {min-width: 100%; display: block; padding-bottom: 4px;}
          }
        }
        @include respond-to(media-lg) {font-size: 15px; line-height: 20px;}
        &:first-child {padding-top: 0 !important; border-top: none;
          @include respond-to(media-sm) {padding-top: 18px !important;}
        }
        .left {color: $grayClr; min-width: 160px;
          @include respond-to(media-lg) {min-width: 110px;}
        }
      }
    }
  }
}

.downloadBtn {font-size: 13px; padding: 0 15px; height: 26px; border-radius: 4px;}