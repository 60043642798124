@import '../assets/css/mixin.scss';
.footerCon {background: #0f0708 url(../assets/images/footer_bg.jpg) no-repeat center top; padding-top: 120px;
  @include respond-to(media-lg) {background-size: 80% auto; padding-top: 100px;}
  @include respond-to(media-sm) {padding-top: 70px;}
  @include respond-to(media-xs) {padding-top: 50px;}
}
.footerLeft {max-width: 450px;
  @include respond-to(media-sm) {max-width: 100%;}
}
.footerText {color: #D6D6D6; font-size: 16px; line-height: 26px;}
.communityHd {color: #D6D6D6; font-size: 20px;}
.socialLink {margin: -5px;
  li {padding: 5px;
    a {display: block; width: 50px; height: 50px; border-radius: 5px; border: 1px solid #444457; display: flex; align-items: center; justify-content: center;
      @include respond-to(media-lg) {width: 46px; height: 46px;}
      @include respond-to(media-sm) {width: 36px; height: 36px;}
      &:hover {border-color: $primary !important; background: $primary !important;}
    }
  }
}

.footerColumn {padding: 0 15px;
  @include respond-to(media-md) {width: 100%;}
  @include respond-to(media-sm) {padding: 0 0 15px 0;}
  .footerHd {font-size: 19px; color: $primary;}
  .footerLinks {
    @include respond-to(media-md) {display: flex; flex-wrap: wrap;}
    li {padding: 4px 0;
      @include respond-to(media-md) {padding: 4px 20px 4px 0;}
      a {font-size: 15px; color: #D6D6D6;
        &:hover {color: $primary !important;}
      }
    }
  }
}

.footerBot {border-top: 1px solid #292929;
  .copy {font-size: 16px; color: #D6D6D6;
    @include respond-to(media-sm) {width: 100%; text-align: center;}
  }
  .footerBR {
    @include respond-to(media-sm) {width: 100%;}
    ul {margin: -5px;
      @include respond-to(media-sm) {justify-content: center;}
      li {padding: 5px;
        a {display: flex; align-items: center; justify-content: center; background: #2F2F3A; border-radius: 5px; width: 50px; height: 50px;}
        &:last-child {
          a {color: #c49910 !important;}
        }
      }
    }
  }
}