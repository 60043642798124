@import '../assets/css/mixin.scss';

.blogTop { overflow: hidden; position: relative;
  &:before {content: ''; background: transparent linear-gradient(180deg, #FFFFFF00 0%, #000000 100%) 0% 0%; position: absolute; left: 0; bottom: 0; width: 100%; height: 70%; z-index: 10;}
  .img { transition: ease-in-out .4s; max-height: 600px; min-height: 200px;
    img {opacity: 0; width: 100%;}
  }
  &:hover {
    .img {transform: scale(1.1);}
    .hd {color: $primary;}
  }
  .det {position: absolute; bottom: 0; left: 0; padding: 30px; width: 100%; height: 100%; display: flex; align-items: flex-end; z-index: 11;
     @include respond-to(media-md) {padding: 20px;}
    .date {font-size: 16px;
      @include respond-to(media-xl) {font-size: 14px;}
      @include respond-to(media-md) {font-size: 13px;}
    }
    .hd {font-size: 30px; transition: ease-in-out .4s;
      @include respond-to(media-xl) {font-size: 26px;}
      @include respond-to(media-md) {font-size: 22px;}
      @include respond-to(media-xs) {font-size: 18px;}
    }
    .text {font-size: 18px; line-height: 28px;
      @include respond-to(media-xl) {font-size: 16px; line-height: 26px;}
      @include respond-to(media-md) {font-size: 15px; line-height: 24px;}
      @include respond-to(media-xs) {display: none !important;}
    }
  }
}

.blogList {margin: -15px -8px;
  @include respond-to(media-sm) {margin: -10px -5px;}
  li {width:25%; padding: 15px 8px;
    @include respond-to(media-md) {width:50%; }
    @include respond-to(media-sm) {width:50%; padding: 10px 5px;}
    a {
      .img {overflow: hidden;
        img {width: 100%; transition: ease-in-out .4s;}
      }
      .date {font-size: 16px;
        @include respond-to(media-xl) {font-size: 14px;}
        @include respond-to(media-md) {font-size: 13px;}
      }
      .hd {font-size: 22px; transition: ease-in-out .4s;
        @include respond-to(media-xl) {font-size: 20px;}
        @include respond-to(media-md) {font-size: 18px;}
        @include respond-to(media-xs) {font-size: 16px;}
      }
      &:hover {
        .img {
          img {transform: scale(1.1);}
        }
        .hd {color: $primary;}
      }
    }
  }
}

.detDate {font-size: 16px;
  @include respond-to(media-xl) {font-size: 14px;}
  @include respond-to(media-md) {font-size: 13px;}
}
.detHd {font-size: 30px; transition: ease-in-out .4s;
  @include respond-to(media-xl) {font-size: 26px;}
  @include respond-to(media-md) {font-size: 22px;}
  @include respond-to(media-xs) {font-size: 20px;}
}
.detText {font-size: 18px; line-height: 28px;
  @include respond-to(media-xl) {font-size: 16px; line-height: 26px;}
  @include respond-to(media-md) {font-size: 15px; line-height: 24px;}
}