@import '../assets/css/mixin.scss';
.aboutTopLeft {width: 50%;
  @include respond-to(media-md) {width: 100%;}
    .inner {width: 100%; padding: 30px; max-width: 850px;
      @include respond-to(media-sm) {padding: 15px;}
    }
}
.aboutText {font-size: 18px; line-height: 28px;
  @include respond-to(media-lg) {font-size: 16px; line-height: 24px;}

}
.aboutTopRight {width: 50%;}
.aboutStatic {
  li {border-right: 1px solid #343434; width: 20%; font-size: 20px;
    @include respond-to(media-md) {font-size: 18px; }
    @include respond-to(media-sm) {width: 33.33%; margin: 8px 0; font-size: 16px;}
    &:last-child {border-right: none;
      @include respond-to(media-sm) {border-right: 1px solid #343434;}
    }
    &:nth-child(3n) {
      @include respond-to(media-sm) {border-right: none;}
    }
    span {font-size: 30px; font-family: $primary-font;
      @include respond-to(media-md) {font-size: 22px; }
      @include respond-to(media-xs) {font-size: 20px; }
    }
    .icon {width: 120px; height: 120px; margin: auto;
      @include respond-to(media-md) {width: 90px; height: 90px;}
      @include respond-to(media-xs) {width: 60px; height: 60px;}
      img {filter: grayscale(100%); max-width: 100%; max-height: 100%; width: auto; height: auto;}
    }
  }
}
.teamList {margin: -15px;
  li {width:14.28%; padding: 15px; font-size: 20px;
    @include respond-to(media-lg) {width:25%; font-size: 18px;}
    @include respond-to(media-xs) {width:50%;}
    span {color: #9D9D9D; font-size: 15px;}
    img {display: block; width: 200px; border-radius: 100%; margin: auto;
      @include respond-to(media-lg) {width:120px;}
    }
  }
}