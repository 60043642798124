@import '../assets/css/mixin.scss';

.header {background: #151618; height: 70px; width: 100%; border-bottom: 1px solid rgba(255,255,255,.3); position: fixed; left: 0; top: 0; z-index: 101;
  @include respond-to(media-lg) {height: 70px;}
}
.logo {padding: 0 30px;
  @include respond-to(media-lg) {padding: 0 15px;}
  img {min-width: 232px;
    @include respond-to(media-lg) {min-width: 200px;}
  }
}
.searchBox {width: 100%; position: relative; padding-right: 50px; border-left: 1px solid rgba(255,255,255,.3); 
  @include respond-to(media-xs) {background: #151618; position: absolute; left: 0; top: 70px; border-bottom: 1px solid rgba(255,255,255,.3); border-left:none; }
}
.searchInput {width: 100%; background: none; border: none; height: 100%; font-size: 18px; padding: 0 20px; color: #fff;
  @include respond-to(media-xs) {padding: 12px 15px; font-size: 16px;}
}
.searchIcon {position: absolute; right: 20px; top: 0; bottom: 0; margin: auto; color: #fff;}
.menu {
  @include respond-to(media-lg) {border-left: 1px solid rgba(255,255,255,.3); padding: 0 15px; display: flex; align-items: center;}
  a.showMobMenu {display: none;
    @include respond-to(media-lg) {display: block; width: 44px; height: 34px; background: $primary; border-radius: 3px; padding-top: 9px; position: relative; z-index: 101;
      i {width: 24px; height: 2px; background: #fff; display: block; margin: 0 auto 5px auto; transition:ease-in-out .3s;}
      &.active {z-index: 10; position: relative;
        i {
          &:nth-child(1)  {transform: rotate(45deg); margin: 7px auto 0 auto;}
          &:nth-child(2)  {transform: rotate(-45deg); margin:-1px auto 0 auto;}
          &:nth-child(3)  {display: none;}
        }
      }
    }
  }
}
.menuLink {height: 100%;
  @include respond-to(media-lg) {position: fixed; width: 300px; height:calc(100% - 69px); right: -300px; bottom: 0; background: #000; display: block !important; border-left: 1px solid rgba(255,255,255,.3); transition: ease-in-out .4s; overflow: auto;
    &.active {right: 0; display: block !important;}
  }
  > li {border-left: 1px solid rgba(255,255,255,.3); width: 100%; white-space: nowrap; height: 100%; position: relative;
    @include respond-to(media-lg) {height: auto; border-left: none; border-top: 1px solid rgba(255,255,255,.3);}
    a {display: flex; height: 100%; font-size: 18px; color: #fff; align-items: center;  padding: 0 30px; width:max-content; position: relative;
      &:hover, &.active {background: $primary;}
      @include respond-to(media-lg) {padding: 20px; width: 100%; display: block;}
    }
    &:before {content: ''; background: $primary; width: 100%; height: 100%; position: absolute; left: 0; top: -100%; transition: ease-in-out .4s;
      @include respond-to(media-lg) {display: none;}
    }
    &:hover {
      &:before {top: 0;}
    }
    &.wallet {border: none; align-items: center; display: flex; padding: 0 5px;
      &::before {background: none;}
      a {background: $primary ; border-radius: 35px; height: 80%; padding: 0 20px;
        @include respond-to(media-lg) {padding: 15px 20px;}
      }
      .icon {margin-right: 5px;}
      img {border-radius: 100%; width: 32px; height: 32px;}
    }
    .submenu {display: none; position: absolute; left: 0; top: 100%; width: 200px; background: #1e1f21;
      @include respond-to(media-lg) {position: static; width: 100%;}
      li {border: none; border-bottom: 1px solid #010101;
        a {font-size: 16px; padding: 15px 20px; color: rgba(255,255,255,.6); width: 100%;
          @include respond-to(media-lg) {padding: 12px 20px;}
        }
      }
    }
    &:hover {
      > a {background: $primary !important;}
      .submenu {display: block;}
    }
  }
}

.headRight {padding: 0 30px; height: 100%;
  @include respond-to(media-sm) {padding: 0 15px;;}
}

.bg {position: fixed; display: none; left: 0; top:0; width: 100%; height: 100%; z-index: 999; background: rgba(0,0,0,.9);
  &.active {display: block;}
  
}
.accountMenu {display: none; overflow: auto; position: fixed; right: 20px; top:20px; bottom: 20px; border-radius: 10px; width: 400px; z-index: 1000; background: $lightBg; padding: 30px;
  &.active {display: block;}
  @include respond-to(media-sm) {width: auto; left: 20px; padding: 20px;}
  .close {position: absolute; right: 10px; top: 10px; color: #fff;}
  ul {
    li {width: 100%; padding: 10px 0;
      a {font-size: 22px; display: flex; align-items: center;
        @include respond-to(media-sm) {font-size: 18px;}
        .icon {width: 28px; height: 28px; display: flex; align-items: center; justify-content: center; margin:4px 12px 0 0;}
        img {max-width: 100%; max-height: 100%;}
        &:hover{color: $primary !important;}
      }
    }
  }
}

.collList {padding: 15px;
  li {display: flex; font-size: 17px; margin-bottom: 15px;
    &:last-child {margin-bottom: 0;}
    img {border-radius: 100%; width: 60px; height: 60px; margin-right: 15px;}
  }
}


// my css

.main{
  height: 70vh;
  width: 100%;
  overflow: auto;
  position:absolute;
  top: 70px;
  left: 0;
  background-color: white;
  z-index: 10001;
  overflow: auto;
}
.popup{
  color: #000;

}
.popup-header{
  display: flex;
  justify-content: space-between;
  padding: 15px 20px;
  border-bottom: 1px solid black;
  color: #000;
  a {color: #000;}
  h3 {font-size: 22px; padding: 0; margin: 0;
  }
}