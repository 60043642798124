@import '../assets/css/mixin.scss';
.leList {margin: -10px;
  @include respond-to(media-lg) {margin: -5px;}
  li {width: 33.33%; padding: 10px;
     @include respond-to(media-lg) {padding: 5px;}
     @include respond-to(media-md) {width: 50%;}
     @include respond-to(media-xs) {width: 100%;}
    .inner {background: #242424; height: 100%; border-radius: 10px; padding: 10px;
      @include respond-to(media-lg) {padding: 5px;}
      .img {position: relative; overflow: hidden;
        
        a {
          img {border-radius: 10px; width: 100%; transition: ease-in-out .4s;}
          &:hover {
            img {transform: scale(1.1);}
          }
        }
        .rotate {width: 50px; position: absolute; right: 15px; top: 15px; cursor: pointer;}
        .item {position: absolute; left: 15px; bottom: 15px; background:$primary; border-radius: 6px; font-size: 16px; padding: 6px 10px;}
      }
      .det {
        .name {font-size: 20px;
          @include respond-to(media-lg) {font-size: 18px;}
        }
        .timer {color: #B5B5B5; font-size: 16px;
          @include respond-to(media-lg) {font-size: 15px;}
        }
        .hb {color: #B5B5B5; font-size: 16px;
          @include respond-to(media-lg) {font-size: 15px;}
        }
        .price {font-size: 18px;
          @include respond-to(media-lg) {font-size: 16px;}
        }
      }
    }
  }
}