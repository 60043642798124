@import '../assets/css/mixin.scss';
.HelpCenterBanner {background-size: cover !important; min-height: 250px; position: relative;
  .img {
    img {width: 100%; opacity: 0;}
  }
  .inner {position: absolute; left: 0; top: 0; height: 100%; width: 100%;}
  .hd {font-size: 40px; font-family: $primary-font;
    @include respond-to(media-lg) {font-size: 32px;}
    @include respond-to(media-sm) {font-size: 24px;}
  }
}
.helpSearch {max-width: 550px; width: 90%; height: 60px; background: #fff; margin: auto; padding-right: 40px;
  .searchIcon {position: absolute; right: 10px; color: #000;}
  .input {width: 100%; height: 60px; background: none; border: none; padding: 0 20px; font-size: 16px;}
}
.helpList {margin: -15px;
  @include respond-to(media-lg) {margin: -10px;}
  li {padding: 15px;
    @include respond-to(media-lg) {padding: 10px;}
    a {display: block; height: 100%; padding: 30px; background: #242424; box-shadow: 0px 3px 0px #31871E; border-radius: 10px; font-size: 20px;
      @include respond-to(media-md) {font-size: 17px; padding: 20px;}
      .icon {width: 77px; height: 77px; margin: 0 auto 20px auto;
        @include respond-to(media-md) {width: 60px; height: 60px;}
      }
      img {filter: grayscale(100%); transition: ease-in .4s; max-width: 100%; max-height: 100%;}
      &:hover {
        img { filter: grayscale(0);}
      }
    }
  }
}

.articleList {margin: -15px;
  @include respond-to(media-lg) {margin: -10px;}
  li {padding: 15px;
    @include respond-to(media-lg) {padding: 10px;}
    a {display: flex; font-size: 16px; line-height: 26px; color: $grayClr; border-radius: 10px; min-height: 50px; height: 100%; align-items: center; padding:10px 20px; background: #151618; border: 1px solid #3B3B3B;
      @include respond-to(media-lg) {font-size: 15px; line-height: 24px; padding:10px 15px;}
      &:hover {border: 1px solid #31871E; color: $whtClr;}
    }
  }
}