@import '../assets/css/mixin.scss';
.topCon {border-bottom: 1px solid #3b3b3b;}

.collectionList {margin:-8px;
  li {width: 25%; padding: 8px;
    @include respond-to(media-md) {width: 33.33%;}
    @include respond-to(media-sm) {width: 50%;}
    @include respond-to(media-xs) {width: 100%;}
    a {display: block; height: 100%; background: #242424; padding: 10px;
      .img {
        img {width: 100%; transition: ease-in-out .4s;}
      }
      .name {font-size: 18px;
        img {width: 42px; height: 42px; border: 3px solid #000;}
      }
      &:hover {color: $primary !important;
        .img {
          img {transform: scale(1.1);}
        }
      }
    }
  }
}