@import '../assets/css/mixin.scss';
.detailBox {
    .left {width: 44%;
      @include respond-to(media-md) {width: 100%; max-width: 500px;}
      img {width: 100%; border: 10px solid #242424; border-radius: 0;}
      .rotate {position: absolute; right: 20px; top: 20px; cursor: pointer;
        img {border:none;}
      }
    }
    .right {width: 56%; padding: 0 0 0 50px;
        @include respond-to(media-md) {width: 100%; padding: 20px 0 0 0;}
        .hd {font-size: 40px; font-family: $primary-font; line-height: 52px; margin-top: -8px;
            @include respond-to(media-lg) {font-size: 34px; line-height: 44px;}
            @include respond-to(media-sm) {font-size: 28px; line-height: 36px;}
          }
        .info {font-size: 15px;
            .id {font-size: 18px;}
            .item {background: #FFFFFF; height: 32px; padding: 0 10px; color: #000;}
            .view {background: #151618; border: 1px solid #707070; height: 34px; padding: 0 10px;
                svg {width: 18px; height: 18px; color: #999;}
            }
            .fav {background: #151618; border: 1px solid #707070; height: 34px; padding: 0 10px;
                svg {width: 18px; height: 18px; color: #999;}
            }
            .collectible {background: #1A1B1D; border: 1px solid #2A2A2A; height: 34px; padding: 0 10px;}
        }
        .desc {font-size: 18px; line-height: 28px; color: $grayClr;
            @include respond-to(media-lg) {font-size: 16px; line-height: 24px;}
        }
        .createrBox {
            .img {width: 50px; min-width: 50px;
                img {width: 50px; height: 50px;}
            }
            .det {font-size: 16px;
                span {font-size: 14px; color: $grayClr;}
            }
        }
        .detList {background: none; border-radius: 0; padding: 0;
            ul {
                li {width: 33.33%;
                    @include respond-to(media-xs) {width: 50%;}
                }
            }
        }
        .detPrice {font-size: 30px;
            span {color: $grayClr; font-size: 16px;}
            .hBar {width: 32px; height: 32px; background: #fff;
                img {width: 16px;}
            }
        }
        .placeBid {background: #303030; border-color: #303030;}
    }
}
.productDetTab {background: #1A1B1D; border: 1px solid #2A2A2A; border-radius: 6px; padding: 5px;
     a {margin: 5px; display: flex; align-items: center; justify-content: center; height: 50px; background: #1A1B1D; border: 1px dashed #414141; border-radius: 6px; color: $whtClr; width: 30%; max-width: 200px;
        &.active, &:hover {background: #A020F0; color: #fff; border-color: #A020F0;}
    }
}



.checkoutPopup { height:100%; overflow: auto;
    .modal-dialog {max-width: 700px; height: 100%; margin: auto; padding: 20px; display: flex;   flex-flow: row wrap; align-items: center;}
    .modal-content {background: $lightBg; padding: 30px; position: relative;
      @include respond-to(media-sm) {padding: 0;}  
      .close {position: absolute; right: 8px; top: 8px; color: #fff;}
      .modal-body {position: static; width: 100%; margin: auto;}
    }
  }

  .checkoutTable {
    table {
        th {padding: 10px 0; border-bottom: 1px solid rgba(255,255,255,.1); font-weight: 500;}
        td {padding: 20px 0; border-bottom: 1px solid rgba(255,255,255,.1);
            .info {
                .img {min-width: 100px; width: 100px;
                    @include respond-to(media-sm) {min-width: 80px; width: 80px;}  
                    img {width: 100px; border: 4px solid #414141; border-radius: 3px;
                        @include respond-to(media-sm) {width: 80px;}  
                    }
                }
            }
            .priceT {font-size: 20px;
                .icon {background: #fff; width: 16px; height: 16px; line-height: 12px;
                    img {width: 8px;}
                }
            }
            .priceB {font-size: 14px; color: $grayClr;}
        }
    }
  }

  .favLink {position: absolute; right: 80px; top: 20px; width: 50px; height: 50px; background: #000; border-radius: 100%; display: flex; align-items: center; justify-content: center;
    span {position: absolute;
        background: red;
        left: -6px;
        top: -4px;
        width: 20px;
        height: 20px;
        border-radius: 100%;
        display: flex;
        align-items: center;
        justify-content: center;}
}

.productImg {max-width: 140px; background: #ccc; padding: 4px; border-radius: 6px;
    img {border-radius: 6px;}
}
.radioBx {background: #eee; border-radius: 3px; padding: 10px 20px; margin-bottom: 5px; color: #000;}
.timer {margin: 10px 0 10px 0; padding: 0;
    li {width: 80px; text-align: center; background: #333; font-size: 30px; font-weight: 600; border-radius: 5px; padding: 10px; color: #fff; margin-right: 05px;
        span {display: block; font-size: 15px;}
    }
}