@import '../assets/css/mixin.scss';
.bestCollection {width: calc(50% - 30px);
  @include respond-to(media-md) {width: 100%;}
}
.collectionSlider {
  .item {
    a {display: block; position: relative; overflow: hidden; color: #fff; border-radius:10px;
      img {transition: ease-in-out .4s; border-radius:10px; width: 100%;}
      &:hover {
        img {transform: scale(1.1);}
      }
      .over {background: #242424; position: absolute; left: 0; top: 0; width: 100%; height: 100%; text-align: center; display: flex; align-items: center; transition: ease-in-out .3s; transform: scale(0); opacity: 0;
        .inner {width: 100%; display: flex; flex-direction: column;
          .name {display: block; color: #fff; font-size: 16px; font-weight: 600;
            @include respond-to(media-md) {font-size: 14px;}
          }
          .info {display: block; padding-top: 15px; color: #666; font-size: 15px;
            @include respond-to(media-md) {font-size: 13px; padding-top: 8px;}
            span {display: block; font-size: 16px; color: #fff; padding-top: 5px;
              @include respond-to(media-md) {font-size: 15px;}
            }
          }
        }
      }
    }
    &:hover {
      .over {opacity: 1; transform: scale(1);}
    }
  }
  .slick-arrow {position: absolute; opacity: 1; z-index: 20; width: 40px; height: 40px; background: #fff; border-radius: 100%;
    &:hover {background: #fff; opacity: .8;}
    &.slick-disabled {background: #fff; opacity: .8; cursor:not-allowed;}
    
    &.slick-prev {left: -15px;
      &:before {font-family: 'Material Icons'; content: "arrow_left"; font-size: 32px; color: #000;}
    }
    &.slick-next {right: -15px;
      &:before {font-family: 'Material Icons'; content: "arrow_right"; font-size:32px; color: #000;}
    }
  }
}

.collectionSlider {
  .slick-list {margin: 0 -5px; display: flex;
    .slick-track{display: flex; margin: 0;
      .slick-slide {padding: 0 5px; height: 100%;
        > div {height: 100%;}
      }
    }
  }
  }