@import '../assets/css/mixin.scss';
.relatedItem {margin-bottom: 50px;
  .itemBox {background: #242424; height: 100%; border-radius: 10px; padding: 10px;
      @include respond-to(media-lg) {padding: 5px;}
      .img {position: relative; overflow: hidden;
        
        a {
          img {border-radius: 10px; width: 100%; transition: ease-in-out .4s;}
          &:hover {
            img {transform: scale(1.1);}
          }
        }
        .rotate {width: 50px; position: absolute; right: 15px; top: 15px; cursor: pointer;}
        .item {position: absolute; left: 15px; bottom: 15px; background:$primary; border-radius: 6px; font-size: 16px; padding: 6px 10px;}
      }
      .userName {font-size: 18px; padding: 12px 15px;
        span {font-size: 16px; color: #B5B5B5;}
      }
      .price {border: 1px solid #343434; border-radius: 10px; font-size: 15px; color: #B5B5B5; padding: 5px 15px 10px 15px;
        span {color: #fff; font-size: 16px;}
      }
    }
}