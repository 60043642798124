@import '../assets/css/mixin.scss';
.dayTab {background: #151618; border: 1px solid #2E2E2E; border-radius: 10px; padding: 1px;
  li {font-size: 17px; padding: 1px;
    @include respond-to(media-lg) {font-size: 15px;}
    a {color: #BCBCBC; display: block; padding: 8px 20px; border-radius: 10px;
      @include respond-to(media-lg) {padding: 8px 15px;}
      &:hover, &.active {background: #000;}
    }
  }
}

.topCollectoion {
  .item {height: 100%; background: url(../assets/images/texture.png) no-repeat center; background-size: cover; border-radius: 10px; padding: 50px;
    @include respond-to(media-lg) {padding: 25px;}
    .img {
      img {display: block; border-radius: 100%; margin: auto;}
    }
    .name {font-size: 20px;
      @include respond-to(media-lg) {font-size: 18px;}
    }
    .text {font-size:18px;
      @include respond-to(media-lg) {font-size: 16px;}
    }
    .price {background: #1E1E1E; border: 1px solid #636363; border-radius: 4px; font-size: 15px; color: #B1B1B1; padding:8px;}
    a {color: #fff;
      &:hover {color: $primary !important;}
    }
  }
}