
/*Media Query Mixing*/
@mixin respond-to($breakpoint) {
    @if $breakpoint == "media-xs" {
      @media (max-width: 575px) {
        @content;
      }
    }
    @if $breakpoint == "media-sm" {
        @media (max-width: 767px) {
          @content;
        }
      }
    
    @else if $breakpoint == "media-md" {
      @media (max-width: 991px) {
        @content;
      }
    }
  
    @else if $breakpoint == "media-lg" {
      @media (max-width: 1199px) {
        @content;
      }
    }
    @else if $breakpoint == "media-xl" {
        @media (max-width:1499px) {
            @content;
        }
    }
  }
  $primary:#31871E;
  $primary-font: 'DM Serif Display', serif;
  $whtClr:#ffffff;
  $grayClr:#A1A1A1;
  $darkBg:#0E0F10;
  $lightBg:#313030;