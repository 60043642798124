@import '../assets/css/mixin.scss';
.listingLeft {width: 380px; position: relative;
    @include respond-to(media-lg) {width: 320px;}
    .inner {background: #0D0E10; border: 1px solid #262626; border-radius: 10px; padding: 20px;
        @include respond-to(media-md) {display: none;}
        &.open {
            @include respond-to(media-md) {display: block; padding-bottom: 60px; position: fixed; left: 0; top: 0; width: 320px; height: 100%; overflow: auto; z-index: 1001;}  
            @include respond-to(media-xs) {width: 100%;}  
        }
    }
    .allBtn {width: 100%; background: #303030; border-color: #303030;}
    .listHd {font-size: 20px;}
}
a.filterLink {display: none;
    @include respond-to(media-md) {display: block; border: 1px solid #474747; background-color: #151618; width: 50px; height: 50px; position: absolute; top: -66px; left: 0; border-radius: 10px; text-align: center; line-height: 46px;}
}

.priceRange {color: #fff;
    .box {width: 30%;}
    .selectBox {width: 40%;}
    .formInput {background-color: #0D0E10; border: 1px solid #B9B9B9; border-radius: 3px; height: 46px; font-size: 15px;}
}

.listingRight {width: calc(100% - 380px); padding-left: 16px;
    @include respond-to(media-lg) {width: calc(100% - 320px); padding-left: 0;}
    @include respond-to(media-md) {width: 100%;}
    .productList {
        li {width: 33.33%;
            @include respond-to(media-lg) {width: 50%;}
            @include respond-to(media-xs) {width: 100%;}
        }
    }
}

.listingTop {
    @include respond-to(media-md) {padding-left: 60px;}
    @include respond-to(media-xs) {padding-left: 0;}
    .search {width: 70%; height: 50px;
        @include respond-to(media-xs) {width: 100%;}
        .input {height: 50px;}
    }
    .sortBy {width: 30%; padding-left: 10px;
        @include respond-to(media-xs) {width: 100%; padding-left: 0;}
        @include respond-to(media-xs) {padding-left: 60px;}
        .formInput {border: 1px solid #474747; height: 50px; background-color: #151618; font-size: 15px;}
    }
}

.listingAccordian  {
    .accordion-item {background: none; border: none;}
    .accordion-header {background: none; border: none; margin-bottom: 15px;
        button {padding: 0; background: none; color: #fff; font-size: 20px; box-shadow: none;
            &:after {display: none;}
            &:before {font-family: 'Material Icons'; content: "expand_more"; font-size:24px; color: #fff; position: absolute; right: 0; top: 50%; margin-top: -12px; transform: rotate(180deg);}
            &.collapsed {
                &:before {transform: rotate(0deg);}
            }
        }
    }
    .accordion-body {padding: 0;}
}

.applyBtn {display: none;
    @include respond-to(media-md) {display: block; position: fixed; left: 0; bottom: 0; width: 320px;
        .btn {width: 100%; border-radius: 0;}
    }
    @include respond-to(media-xs) {width: 100%;}
}