@import '../assets/css/mixin.scss';
.csLeft {width: 48%;
  @include respond-to(media-md) {width: 100%;}
}
.csList {
  li {background: #242424; border-radius: 10px; padding: 20px; position: relative; margin: 0 0 10px 80px; color: #D6D6D6; font-size: 17px; line-height: 26px; transition: ease-in-out .4s;
    &:hover {background: #31871E;
      .number {box-shadow: 0 0 0 10px #31871E; background: #31871E; border-color: #fff; transform: scale(1);}
    }
    @include respond-to(media-lg) {padding: 15px; font-size: 16px; line-height: 24px;}
    @include respond-to(media-xs) { margin: 0 0 10px 60px;}
    .hd {display: block; font-size: 22px; color: #fff; padding-bottom: 5px;
      @include respond-to(media-lg) {font-size: 19px;}
    }
    .number {width: 60px; height: 60px; background: #1E1E1E; border: 1px solid #636363; border-radius: 100%; position: absolute; left: -80px; top: 50%; margin-top: -30px; font-size: 22px; display: flex; align-items: center; justify-content: center; padding-bottom: 5px; z-index: 10; box-shadow: 0 0 0 10px #151618; transition: ease-in-out .4s; transform: scale(.9);
      @include respond-to(media-xs) {width: 40px; height: 40px; margin-top: -20px; font-size: 18px; left: -60px;}
    }
    .img {width: 120px; margin-right: 15px;
      @include respond-to(media-lg) {width: 90px;}
      @include respond-to(media-xs) {width: 100%;}
      img {
        @include respond-to(media-xs) {width: 80px;}
      }
    }
    .det {width: calc(100% - 135px);
      @include respond-to(media-lg) {width: calc(100% - 120px);}
      @include respond-to(media-xs) {width: 100%; padding-top: 10px;}
    }
    &:before {content: ''; width: 1px; height: 50%; background: rgba(255,255,255,.3); position: absolute; left: -50px; top: 0;
      @include respond-to(media-xs) {left: -40px;}
    }
    &:after {content: ''; width: 1px; height:calc(50% + 10px); background: rgba(255,255,255,.3); position: absolute; left: -50px; bottom: -10px;
      @include respond-to(media-xs) {left: -40px;}
    }
    &:first-child {
      &::before {display: none;}
    }
    &:last-child {
      &::after {display: none;}
    }
  }
}
.csRight {width: 48%; padding-top: 20px;
  @include respond-to(media-md) {display: none;}
  img {width: 75%; max-width: 640px;
    @include respond-to(media-lg) {width: 90%;}
  }
}
.lampVideo {width: 100%;
  .videoInner {max-width: 550px; margin: auto;
    video {border-radius: 100%; border: 10px solid $primary; vertical-align: top;}
  }
}